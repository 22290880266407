/* Add blur effect to the background */
.blur-background {
  filter: blur(5px);
}

/* Style for the modal */
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  background-color: white;
  border: 1px solid white;
  text-align: center;
  border-radius: 10px;
  height: 420px;
  width: 655px;
}

.popup-header{
  text-align: left;
  font-size: 13px;
  
}
.popup-header p{
  font-weight: bold;
  margin-left: 10px;
}

.popup-content{
  display: flex;
  margin: 0;
  padding: 0%;
}

.popup-leftmenu{
  width: 115px;
  text-align: left;
  margin-top: 10px;
}

.popup-sub-leftmenu{
  width: 100px;
  text-align: center;
  margin-top: 10px;
}

.popup-leftmenu img{
  width: 12px;
  height: 12px;
  margin-right: 5px;
}


.popup-leftmenu p.selected {
  background-color: #1e2b3a;
  color: white;

}

.popup-sub-leftmenu p.selected {
  background-color: #1e2b3a;
  color: white;
}


.popup-leftmenu p.selected img {
  filter: invert(100%);
}

.popup-leftmenu p{
  font-size: 12px;
  padding-left: 10px;
  cursor: pointer;
  padding: 10px;
  margin: 0%;
}

.popup-leftmenu p:hover{
  background-color: rgb(236, 233, 233);
}

.popup-leftmenu p.selected:hover{
  background-color: #1e2b3a;
  color: white;
}

.clickme{
  text-decoration: underline;
  font-weight: bolder;
}

.dropzone {
  width:  530px;
  height: 365px;
  border: 1px solid #1e2b3a;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  border-radius: 10px;
}
  
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 0px !important;
}
.css-zr2egw-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  border: 1px solid #674f87 !important;
}
.css-6hp17o-MuiList-root-MuiMenu-list {
  padding-bottom: 0% !important;
  padding-top: 13px !important;
}

.upload-container img {
  max-width: 100px;
  margin-top: 70px;
}
.upload-container p{
  padding: 0;
  margin: 0;
  font-size: 12px;
  color: #674f87;
}

.afterSelection img{
  width: 340px;
  height: 280px;
}


.loading-spinner {
  border: 5px solid rgba(0, 0, 0, 0.1);
  border-top: 5px solid #000;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  position: relative;
  top: 40%;
  left: 45%;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* For screens smaller than 600px */
@media only screen and (max-width: 600px) {
  .modal {
    width: 90%; /* Adjust modal width */
    height: auto; /* Allow height to adjust based on content */
    max-height: 80%; /* Limit maximum height */
  }

  .popup-leftmenu {
    width: 100%; /* Make left menu full width */
    margin-top: 0; /* Remove top margin */
  }

  .dropzone {
    width: 90%; /* Adjust dropzone width */
    height: 200px; /* Adjust dropzone height */
  }

  .upload-container img {
    margin-top: 30px; /* Adjust top margin */
  }

  .afterSelection img {
    width: 90%; /* Adjust selected image width */
    height: auto; /* Allow height to adjust based on content */
    max-height: 200px; /* Limit maximum height */
  }
}
